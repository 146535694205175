@keyframes show-modal {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal_parent {
  position: fixed;
  display: flex;
  justify-content: end;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.83);
  -webkit-backdrop-filter: blur(5px);
  z-index: 100;
  color: white;

  &.entering {
    animation: show-modal .4s forwards;
  }

  &.exiting {
    animation: show-modal .4s reverse forwards;
  }

  .container {
    margin: auto;
    position: relative;
    max-width: 820px;
    width: 100%;
  }

  .h1 {
    margin: 25px 0 20px 0 !important;
    display: flex;
    justify-content: space-between;
    font-family: "SF Pro Medium", sans-serif;
    font-size: 64px;
    line-height: 76px;
    text-transform: uppercase;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .content {
    padding: 13px 20px;
    width: 100%;
    background: #757A88;
    border-radius: 18px;
  }

  .row {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label, .value {
      font-size: 20px;
      line-height: 24px;
    }

    .label {
      margin-right: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: darkgrey;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #3a3aef;
  }
}

@media (max-width: 860px) {
  .modal_parent {
    .h1 {
      font-size: 28px;
      line-height: 33px;

      .close {
        top: 25px;
        right: 30px;
      }
    }

    .container {
      max-width: calc(100vw - 30px);
      margin: 30px 15px 0 0;

      .content {
        max-height: calc(100vh - 30%);
        overflow-y: auto;

        .row {
          margin-bottom: 10px;
          padding-bottom: 5px;
          flex-direction: column;
          border-bottom: 1px solid white;

          .value, .label {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}