@font-face {
  font-family: 'SF Pro';
  src: url('SFProDisplay-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Medium';
  src: url('SFProDisplay-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro SemiBold';
  src: url('SFProDisplaySemibold.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
