.preview {
  padding-top: 80px;
  position: relative;
  color: white;
  z-index: 2;
  scroll-margin-top: 80px;

  .container {
    display: flex;
  }

  .left_col {
    max-width: 562px;
    width: 100%;

    .title {
      margin-bottom: 20px;

      div:nth-child(1) {
        font-family: "SF Pro Medium", sans-serif;
        font-size: 90px;
        line-height: 119px;
        text-transform: uppercase;
        color: var(--green);
      }

      div:nth-child(2) {
        display: flex;

        span {
          font-family: "SF Pro Medium", sans-serif;
          font-size: 90px;
          line-height: 119px;
          text-transform: uppercase;

          &:first-child {
            color: #343843;
            margin-right: 20px;
          }
        }
      }
    }

    .info {
      margin-bottom: 38px;
      max-width: 400px;
      opacity: 0.6;
    }

    .redirect_whatsapp {
      margin-top: 130px;
      padding: 15px 13px 17px 13px;
      max-width: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--green);
      border-radius: 18px;
      cursor: pointer;
    }
  }

  .right_col {
    position: relative;
    margin-left: -50px;
    max-width: 1010px;
    height: 820px;
    width: 100%;
    background: url("../../assets/laptop.png") no-repeat;

    .laptop_mobile {
      display: none;
    }
  }

}

@media (max-width: 1560px) {
  .preview {
    padding: 75px 15px 0 15px;

    .container {

      .right_col {
        margin-left: -150px;
      }
    }
  }
}

@media (max-width: 1460px) {
  .preview {
    .container {

      .right_col {
        margin: 20px 0 0 -150px;
        height: 800px;
        max-width: calc(100vw - 162px);
        background-position: right;
        background-size: contain;
      }
    }
  }
}

@media (max-width: 1325px) {
  .preview {
    .container {
      flex-direction: column;

      .left_col {
        margin: 0 auto 30px auto;
        max-width: 582px;

        .title {
          div:nth-child(1) {
            margin: 0 auto;
            width: fit-content;
            font-size: 80px;
            line-height: 99px;
          }

          div:nth-child(2) {
            margin: 0 auto;
            width: fit-content;

            span {
              font-size: 80px;
              line-height: 99px;
            }
          }
        }

        .info {
          margin: 0 auto;
          max-width: 500px;
          text-align: center;
        }

        .redirect_whatsapp {
          margin: 50px auto 0 auto;
        }
      }

      .right_col {
        margin: 0 auto;
        max-width: 730px;
      }
    }
  }
}

@media (max-width: 760px) {
  .preview {
    .container {

      .left_col {
        margin-bottom: 0;

        .title {
          div:nth-child(1) {
            font-size: 65px;
            line-height: 75px;
          }

          div:nth-child(2) {
            span {
              font-size: 65px;
              line-height: 75px;
            }
          }
        }

        .redirect_whatsapp {
          margin: 50px auto 0 auto;
        }
      }

      .right_col {
        margin-top: 30px;
        height: 560px;
        max-width: calc(100vw - 35%);
      }
    }
  }
}

@media (max-width: 675px) {
  .preview {
    .container {
      .right_col {
        margin-top: 0;
        height: 560px;
        max-width: calc(100vw - 25%);
      }
    }
  }
}

@media (max-width: 610px) {
  .preview {
    .container {
      .left_col {
        max-width: 345px;

        .title {
          margin-bottom: 10px;

          div:nth-child(1) {
            margin: initial;
            text-align: start;
            font-size: 40px;
            line-height: 48px;
          }

          div:nth-child(2) {
            margin: initial;

            span {
              font-size: 40px;
              line-height: 48px;

              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }

        .info {
          font-size: 13px;
          line-height: 16px;
          max-width: 345px;
          text-align: start;
        }

        .redirect_whatsapp {
          max-width: 100%;
          margin: 30px auto 0 auto;
        }
      }

      .right_col {
        margin-top: 0;
        height: 470px;
        max-width: 100%;
        /*background: none;*/

        /*.laptop_mobile {
          margin-top: 25px;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        }*/

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .preview {
    .container {

      .right_col {
        height: 400px;
      }
    }
  }
}
