@import 'src/fonts/index.scss';

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

body {
  font-family: "SF Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  overflow-x: hidden;
}

:root {
  --black: #16151B;
  --green: #00A72F;
}

.app {
  position: relative;
  background: var(--black);
}

.container {
  margin: 0 auto;
  max-width: 1523px;
  width: 100%;
}

.gradient_parent {
  position: absolute;
  top: 50%;
  left: -1250px;
  transform: rotate(39.18deg);

  .gradient {
    width: 1420px;
    height: 1160px;
    background: conic-gradient(from 172.76deg at 46.01% 36.25%, #30A8FF 0deg, rgba(50, 174, 85, 0.96) 82.07deg, rgba(69, 88, 136, 0.51) 191.25deg, rgba(114, 48, 255, 0.87) 253.13deg, rgba(72, 129, 237, 0.32) 341.5deg, rgba(17, 34, 67, 0.96) 360deg);
    filter: blur(66px);
    transform: rotate(174.68deg);
    z-index: 0;
  }
}

.input_form, .phone_mask {
  margin-bottom: 10px;
  padding: 14px 20px 13px 20px;
  width: 100%;
  color: white;
  background: #757A88;
  border-radius: 18px;
  border: 1px solid transparent;
  outline: none;
  font-size: 16px;
  font-family: "SF Pro", sans-serif !important;
  transition: all .3s linear;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &:hover {
    border-color: white;
  }

  &::placeholder {
    font-size: 16px;
    color: #A2A1A4;
  }
}

.fp-overflow {
  /*overflow-y: initial !important;*/
  overflow-x: hidden;
}

.fp-watermark {
  display: none !important;
}

.ant-spin-dot-item {
  background-color: #0033ff !important;
}
