.release_projects {
  position: relative;
  padding-top: 80px;
  color: white;
  overflow: hidden;
  z-index: 3;
  scroll-margin-top: 80px;

  .title {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    font-family: "SF Pro Medium", sans-serif;
    font-size: 64px;
    line-height: 76px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .content {
    display: flex;
    height: 100%;

    .left_col {
      margin-right: 10px;
      height: 100%;
      width: 100%;
      max-width: 750px;

      .preview_image {
        margin-bottom: 15px;
        width: 100%;
        max-width: 750px;
        height: 420px;
        background: url("../../assets/inteh_icon.png") no-repeat;
        cursor: pointer;


        &:first-child {
          background: url("../../assets/sst_icon.png") no-repeat;
        }
      }

      .name {
        margin-bottom: 5px;
        font-family: "SF Pro Medium", sans-serif;
        font-size: 32px;
        line-height: 38px;
        cursor: pointer;
        transition: all .3s linear;

        &:hover {
          color: var(--green);
        }
      }

      .info {
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 19px;
        color: #A2A1A4;
      }
    }

    .right_col {
      display: flex;
      flex-direction: column;
      width: 100%;

      .preview_image {
        margin-bottom: 15px;
        max-width: 760px;
        width: 100%;
        height: 100%;
        max-height: 943px;
        background: url("../../assets/rudn_icon.png") no-repeat;
        cursor: pointer;
      }

      .name {
        margin-bottom: 5px;
        font-family: "SF Pro Medium", sans-serif;
        font-size: 32px;
        line-height: 38px;
        cursor: pointer;
        transition: all .3s linear;

        &:hover {
          color: var(--green);
        }
      }

      .info {
        font-size: 16px;
        line-height: 19px;
        color: #A2A1A4;
      }
    }

    .mobile {
      display: none;
    }
  }
}

@media (max-width: 1570px) {
  .release_projects {
    padding: 80px 15px 0 15px;
  }
}

@media (max-width: 1350px) {
  .release_projects {

    .content {
      margin: 0 auto;
      flex-direction: column;
      width: 100%;

      .left_col, .right_col {
        margin: 0 auto;

        .preview_image {
          background-size: cover !important;
          background-position: center !important;
          border-radius: 18px;
        }
      }

      .right_col {
        max-width: 750px;

        .preview_image {
          height: 700px;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .release_projects {
    margin-top: -30px;

    .title {
      flex-direction: column;
      margin: 0 auto 10px auto;
      max-width: 755px;

      span {
        width: fit-content;
        font-size: 28px;
        line-height: 33px;

        &:last-child {
          align-self: end;
        }
      }
    }

    .content {
      .left_col, .right_col {
        .name {
          font-size: 20px;
          line-height: 24px;
        }

        .info {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .release_projects {
    margin-top: -60px;

    .content {
      padding: 0 15px;

      .left_col, .right_col {

        .preview_image {
          background: none !important;
          height: initial;

          img {
            width: 100%;
            height: auto;
            display: block !important;
          }
        }
      }
    }
  }
}