.start_project {
  padding-top: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  scroll-margin-top: 80px;
  z-index: 2;

  .content {
    padding: 50px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    background: #1D1E25;
    border-radius: 40px;

    .left_col {
      margin-right: 135px;
      max-width: 380px;
      width: 100%;

      .h1 {
        margin-bottom: 15px;
        font-family: "SF Pro Medium", sans-serif;
        font-size: 64px;
        line-height: 76px;
        text-transform: uppercase;
      }

      .text {
        font-size: 20px;
        line-height: 24px;
        color: #A2A1A4;
      }
    }

    .form_section {
      max-width: 780px;
      width: 100%;

      .row {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .input_container {
          position: relative;
          margin-right: 10px;
          width: 33%;

          .input_form {
            margin-bottom: 0;
            transition: all .3s linear;

            &.error {
              border-color: red !important;
            }
          }

          .required {
            color: red;
            position: absolute;
            font-size: 16px;
            top: -15px;
            right: 0;
            width: 10px;
            height: 100px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .phone_mask {
          margin-bottom: 0;
          transition: all .3s linear;

          &.error {
            border-color: red !important;
          }
        }

        .select {
          padding: 12px 20px 14px 20px;
          margin-right: 10px;
          position: relative;
          width: 33%;
          background: #757A88;
          border-radius: 18px;
          border: 1px solid transparent;
          transition: all .3s linear;
          cursor: pointer;

          .arrow {
            position: absolute;
            top: 2px;
            right: 0;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            img {
              user-select: none;
              transition: all .3s linear;
            }
          }

          .label {
            user-select: none;
            color: #A2A1A4;
            white-space: nowrap;
            max-width: 210px;
            text-overflow: ellipsis;
            overflow: hidden;

            &.active {
              color: white;
            }
          }

          &_children {
            position: absolute;
            padding-top: 15px;
            width: 100%;
            left: 0;
            top: 30px;
            opacity: 0;
            visibility: hidden;
            max-height: 0;
            background: #757A88;
            z-index: 3;
            transition: all .3s linear;
            border-radius: 0 0 18px 18px;

            div {
              padding: 10px 15px;
              background: #757A88;

              &:hover {
                background: darken(#757A88, 5%);
              }

              &.active {
                background: darken(#757A88, 5%);
                color: #A2A1A4;
              }

              &:last-child {
                border-radius: 0 0 18px 18px;
              }
            }

            &.open {
              opacity: 1;
              visibility: initial;
              max-height: 200px;
            }
          }

          &.open {
            .arrow {
              img {
                transform: rotate(180deg);
              }
            }
          }

          &.error {
            border-color: red !important;
          }
        }

        .upload_file {
          padding: 10px 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33%;
          max-width: 257px;
          border: 1px solid #757A88;
          border-radius: 18px;
          cursor: pointer;
          background-image: linear-gradient(#4ed13d, #4ed13d);
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 0% 100%;
          transition: background-size .5s, color .5s;
          white-space: nowrap;

          span {
            margin-right: 10px;
            transition: all .3s linear;
          }

          &:hover {
            background-size: 100% 100%;
          }
        }

        .text_area {
          padding: 13px 20px 14px 20px;
          width: 100%;
          color: white;
          background: #757A88;
          border-radius: 18px;
          border: 1px solid transparent;
          outline: none;
          resize: none;
          font-size: 16px;
          font-family: "SF Pro", sans-serif !important;
          transition: all .3s linear;

          &:hover {
            border-color: white;
          }

          &::placeholder {
            font-family: "SF Pro", sans-serif !important;
            font-size: 16px;
            color: #A2A1A4;
          }

          &.error {
            border-color: red !important;
          }
        }

        .btn_send {
          padding: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 200px;
          width: 100%;
          background: var(--green);
          border-radius: 18px;
          cursor: pointer;
          transition: all .3s linear;
          user-select: none;

          &.disabled {
            background: #474C5A;
            cursor: auto;
          }
        }

        .disclosure_agreement {
          display: flex;
          align-items: center;
          max-width: 345px;

          .checkbox_group {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: fit-content;
            user-select: none;

            label {
              cursor: pointer;
              display: flex;
            }

            label:before {
              content: '';
              display: inline-block;
              margin-right: 15px;
              -webkit-appearance: none;
              position: relative;
              width: 25px;
              min-width: 25px;
              height: 25px;
              cursor: pointer;
              border: 1px solid #474C5A;
              border-radius: 30px;
              background: transparent;
            }

            label:after {

            }

            input {
              padding: 0;
              height: initial;
              width: initial;
              margin-bottom: 0;
              display: none;
              cursor: pointer;
            }

            input:checked + label:after {
              content: '';
              position: absolute;
              left: 11px;
              top: 5px;
              width: 5px;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }

            input:checked + label:before {
              background: var(--green);
            }
          }

          .text {
            text-align: center;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 1570px) {
  .start_project {
    .container {
      padding: 0 15px;
    }
  }
}

@media (max-width: 1370px) {
  .start_project {
    .content {
      .left_col {
        margin-right: 0;
      }

      .form_section {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1280px) {
  .start_project {
    .content {
      padding: 30px;
      margin: 0 auto 30px auto;
      max-width: 755px;
      flex-direction: column;

      .left_col {
        max-width: 755px;
        margin: 0 auto 30px auto;
        text-align: center;

        .text {
          max-width: 500px;
          width: fit-content;
          margin: 0 auto;
        }
      }

      .form_section {
        .row {
          &:nth-child(4), &:nth-child(5) {
            margin: 0 auto 20px auto;
            width: 100%;
            max-width: 345px;
          }

          &:nth-child(5) {
            margin-bottom: 0;

            .btn_send {
              max-width: 345px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .start_project {
    .content {
      padding: 30px;
      margin: 0 auto 30px auto;
      max-width: 755px;
      flex-direction: column;

      .left_col {
        max-width: 755px;
        margin: 0 auto 30px auto;
        text-align: center;

        .text {
          max-width: 500px;
          width: fit-content;
          margin: 0 auto;
        }
      }

      .form_section {
        .row {
          &:nth-child(4), &:nth-child(5) {
            margin: 0 auto 20px auto;
            width: 100%;
            max-width: 345px;
          }

          &:nth-child(5) {
            margin-bottom: 0;

            .btn_send {
              max-width: 345px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .start_project {
    .content {

      .left_col {
        .h1 {
          font-size: 28px;
          line-height: 33px;
          text-align: start;
        }

        .text {
          margin: 0;
          max-width: 350px;
          font-size: 16px;
          line-height: 19px;
          text-align: start;
        }
      }

      .form_section {
        .row {
          margin-bottom: 0;
          flex-direction: column;

          .input_container, .select, .upload_file {
            width: 100%;
            margin: 0 0 15px 0;

            .required {
              top: -12px;
            }

            .select {
              margin-bottom: 0;
            }
          }

          .upload_file {
            max-width: 100%;
          }

          .disclosure_agreement .checkbox_group label:before {
            margin-right: 0;
            align-self: center;
          }
        }
      }
    }
  }
}