.products_company {
  position: relative;
  margin-top: 80px;
  color: white;
  z-index: 3;

  .fp-overflow {
    overflow-y: initial;
  }

  .title {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    font-family: "SF Pro Medium", sans-serif;
    font-size: 64px;
    line-height: 76px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .content {
    margin: 0 auto;
    display: flex;
    /*height: calc(100vh - 230px);*/
    /*max-width: 1470px;*/
    width: 100%;
  }

  .col {
    padding: 30px;
    margin-right: 10px;
    height: 850px;
    /*max-height: calc(100vh - 185px);*/
    position: relative;
    width: 100%;
    max-width: 755px;
    overflow: hidden;
    background: #1D1E25;
    border-radius: 40px 0 0 40px;

    .figures {
      position: absolute;
      top: 0;
      right: 0;
    }

    .main {
      position: relative;
      max-width: 525px;
      width: 100%;
      z-index: 1;

      .icon {
        margin-bottom: 15px;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        margin-bottom: 15px;

        span {
          font-size: 32px;
          line-height: 38px;
          font-family: "SF Pro Medium", sans-serif;

          /*&:first-child {
            color: #00A72F;
          }*/
        }
      }

      .info {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 19px;
        color: #A2A1A4;
      }

      .list {
        margin-bottom: 30px;

        li {
          margin-bottom: 15px;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          span {
            font-size: 16px;
            line-height: 19px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .col:last-child {
    margin-right: 0;
    width: 100%;
    max-width: 755px;
    background: url("../../assets/alan.png") no-repeat;
    border-radius: 0 40px 40px 0;

    .main {
      max-width: 510px;
      width: 100%;
    }

    .text {
      span:first-child {
        color: white;
      }

      span:last-child {
        color: #00A72F;
      }
    }
  }
}

@media (max-width: 1200px) {
  .products_company {
    /*margin-top: -80px;*/
  }
}

@media (max-width: 950px) {
  .products_company {
    margin-top: 30px;

    .title {
      flex-direction: column;
      margin: 0 auto 10px auto;
      max-width: 755px;

      span {
        width: fit-content;
        font-size: 28px;
        line-height: 33px;

        &:last-child {
          align-self: end;
        }
      }
    }

    .content {
      flex-direction: column;

      .col {
        padding: 15px;
        margin: 0 auto 10px auto;
        height: initial;
        border-radius: 18px 18px 0 0;

        .main {
          .icon {
            width: 40px;
            height: 40px;

            img {
              width: 40px;
              height: 40px;
            }
          }
        }

        .skillometer_preview {
          margin: 0 auto;
          position: relative;
          width: fit-content;
          z-index: 1;
        }

        &:last-child {
          background-position: bottom;
          height: 700px;
          border-radius: 0 0 18px 18px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .products_company {
    margin: 30px 15px 30px 15px;

    .title {
      flex-direction: column;
      margin: 0 auto 10px auto;
      max-width: 755px;

      span {
        width: fit-content;
        font-size: 28px;
        line-height: 33px;

        &:last-child {
          align-self: end;
        }
      }
    }

    .content {
      flex-direction: column;

      .col {
        padding: 15px;
        margin: 0 auto 10px auto;
        border-radius: 18px 18px 0 0;

        .main {
          .icon {
            width: 40px;
            height: 40px;

            img {
              width: 40px;
              height: 40px;
            }
          }

          .text {
            span {
              font-size: 24px;
              line-height: 29px;
            }
          }

          .info {
            font-size: 13px;
            line-height: 16px;
          }

          .list {
            li {
              margin-bottom: 10px;

              img {
                width: 20px;
                height: 20px;
              }

              span {
                font-size: 13px;
                line-height: 16px;
              }
            }
          }

        }

        .skillometer_preview {
          width: 100%;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }

        &:last-child {
          border-radius: 0 0 18px 18px !important;

        }
      }
    }
  }
}

@media (max-width: 480px) {
  .products_company {
    margin: 30px 15px 30px 15px;

  }
}