.page_release {
  padding-top: 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1524px;
  width: 100%;
  color: white;

  .preview_img {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .info {
      position: absolute;
      top: 57px;
      left: 106px;

      .h1 {
        color: #1D1E25;
        font-size: 90px;
        line-height: 100px;
        font-family: "SF Pro Medium", sans-serif;
        text-transform: uppercase;
      }

      .text {
        max-width: 240px;
        width: 100%;
        color: #757A88;
        font-size: 16px;
      }
    }
  }

  .container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
  }

  .text_preview {
    margin: 150px 0 150px auto;
    max-width: 655px;
    width: 100%;
    font-size: 32px;
    line-height: 38px;
  }

  .img {
    img {
      width: 100%;
      height: auto;
    }
  }

  .slide_1 {
  }

  .info_row {
    margin: 150px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    span:first-child {
      font-family: "SF Pro SemiBold", sans-serif;
      font-size: 32px;
      line-height: 32px;
      text-transform: uppercase;
    }

    span:last-child {
      max-width: 655px;
      width: 100%;
      color: #757A88;
      font-size: 16px;
    }
  }

  .slide_2 {
    img {
      margin-bottom: 5px;
    }

    div {
      img {
        max-width: 645px;
        height: auto;
      }

      img:first-child {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 1340px) {
  .page_release {
    padding: 80px 15px 0 15px;
  }

}