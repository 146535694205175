.company_about {
  position: relative;
  padding-top: 80px;
  color: white;
  overflow: hidden;
  z-index: 2;
  /*
  scroll-margin-top: 80px;
  */

  .fp-overflow {
    overflow-y: initial !important;
    overflow-x: initial;
  }

  .h1 {
    margin-bottom: 40px;
    font-family: "SF Pro Medium", sans-serif;
    font-size: 64px;
    line-height: 84px;
    text-transform: uppercase;
  }

  .container {
    position: relative;
  }

  .main {
    display: flex;
  }

  .left_col {
    max-width: 653px;
    width: 100%;

    .info {
      padding-bottom: 25px;
      margin-bottom: 25px;
      font-size: 32px;
      line-height: 38px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .list {
      width: 100%;

      li {
        padding-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        .count {
          max-width: 325px;
          width: 100%;
          margin-right: 78px;
          font-family: "SF Pro Medium", sans-serif;
          font-size: 90px;
          line-height: 119px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .title {
          margin-left: auto;
          max-width: 250px;
          width: 100%;
          text-align: left;
          font-size: 20px;
          line-height: 24px;
          white-space: nowrap;
          color: #A2A1A4;
        }
      }
    }
  }

  .right_col {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 912px;
    width: 100%;
    z-index: 2;

    .contacts {
      max-width: 500px;
      width: 100%;

      .list {
        li {
          margin-bottom: 25px;
          display: flex;
          align-items: center;

          img {
            margin-right: 15px;
          }

          span {
            font-size: 32px;
            line-height: 38px;
          }

          &:last-child {
            span {
              font-size: 24px;
              line-height: 29px;
            }
          }
        }
      }
    }

    .hand_group {
      position: absolute;
      max-width: 1170px;
      width: 100%;
      top: 270px;
      right: -220px;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
      }

      /* img:last-child {
         position: absolute;
         top: 0;
         right: 0;
         left: 50%;
       }*/
    }
  }

  .gradient_background {
    position: absolute;
    width: 1076px;
    height: 1076px;
    top: 0;
    right: 0;
    z-index: 1;

    .gradient {
      position: absolute;
      width: 1076px;
      height: 706px;
      background: conic-gradient(from 165.77deg at 63.35% 36.25%, #30A8FF 0deg, rgba(149, 174, 50, 0.96) 82.07deg, rgba(69, 88, 136, 0.51) 191.25deg, rgba(48, 56, 255, 0.38) 253.13deg, rgba(72, 129, 237, 0.32) 341.5deg, rgba(17, 34, 67, 0.96) 360deg);
      filter: blur(76px);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .gradient_2 {
      position: absolute;
      width: 511.62px;
      height: 429.48px;
      background: conic-gradient(from 180deg at 36.61% 54.64%, #3077FF 0deg, #093995 360deg);
      filter: blur(53px);
    }
  }

  .gradient_parent {
    top: -543px;
    left: -1221px;
    z-index: -1;
  }
}

@media (max-width: 1570px) {
  .company_about {
    padding: 80px 15px 0 15px;

    .container {
      z-index: 2;
    }
  }
}

@media (max-width: 1280px) {
  .company_about {

    .main {
      margin: 0 auto;
      width: fit-content;
      flex-direction: column;

      .left_col {
        margin-bottom: 30px;
      }

      .right_col {
        padding-bottom: 400px;

        .contacts {
          max-width: 653px;
        }

        .hand_group {
          right: -120px;
        }
      }
    }

    .gradient_background {
      top: 810px;
    }
  }
}

@media (max-width: 950px) {
  .company_about {
    margin-top: -20px;

    .h1 {
      margin: 0 auto 15px auto;
      max-width: 755px;
      font-size: 28px;
      line-height: 33px;
    }

    .main {
      max-width: 755px;
      width: 100%;

      .left_col {
        margin-bottom: 15px;
        max-width: 755px;
        width: 100%;

        .info {
          margin-bottom: 15px;
          font-size: 16px;
          line-height: 19px;
        }

        .list {
          li {
            margin-bottom: 15px;
            flex-direction: column;
            justify-content: start;
            align-items: start;

            .count {
              margin: 0 0 10px 0;
              width: fit-content;
              font-size: 48px;
              line-height: 57px;
            }

            .title {
              margin-left: 0;
            }
          }
        }
      }

      .right_col {
        .contacts {
          max-width: 755px;
          width: 100%;

          .list {
            li {
              img {
                margin-right: 15px;
                width: 30px;
                height: 30px;
              }

              span {
                font-size: 16px;
                line-height: 19px;
              }
            }
          }
        }

        .hand_group {
          top: 220px;
          right: -18%;
        }
      }
    }

    .gradient_background {
      top: 400px;
    }
  }
}

@media (max-width: 650px) {
  .company_about {
    .main {
      .right_col {
        .hand_group {
          top: 210px;
          right: -10%;
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .company_about {
    .main {
      .right_col {
        .hand_group {
          top: 210px;
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .company_about {
    .main {
      .right_col {
        padding-bottom: 230px;

        .hand_group {
          top: 210px;
          right: 0;

          img {
            width: 570px;
          }
        }
      }
    }

    .gradient_background {
      top: 190px;
    }
  }
}
