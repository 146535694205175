.service {
  position: relative;
  /*height: 100vh;*/
  color: white;
  z-index: 3;
  scroll-margin-top: 80px;

  .container {
    position: relative;
    padding: 43px 43px 63px 43px;
    display: flex;
    flex-direction: column;
    background: #1D1E25;
    border-radius: 40px;
    z-index: 1;

    .title {
      margin: 0 auto 34px auto;
      display: flex;
      max-width: 1300px;
      width: 100%;
      justify-content: space-between;
      text-align: center;

      div {
        margin-right: 20px;
        max-width: 400px;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .title_mobile {
      margin-bottom: 15px;
      display: none;
      width: 100%;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-family: "SF Pro Medium", sans-serif;
          font-size: 28px;
          line-height: 33px;
          text-transform: uppercase;
        }

        &:last-child {
          justify-content: center;
        }
      }

    }

    .h1 {
      margin-bottom: 32px;
      text-align: center;
      font-family: "SF Pro Medium", sans-serif;
      font-size: 64px;
      line-height: 84px;
      text-transform: uppercase;
    }

    .list {
      margin: 0 auto;
      display: flex;

      li {
        margin: auto 20px auto 0;
        display: flex;
        flex-direction: column;
        max-width: 400px;
        width: 100%;
        height: 100%;

        .content {
          margin: auto 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          .item {
            padding: 25px 0 25px 25px;
            display: flex;
            flex-direction: column;
            background: #343843;
            border-radius: 30px;
            min-height: 677px;
            max-height: 677px;

            .icon {
              margin-bottom: 20px;
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #474C5A;
              border-radius: 16px;

              img {
                width: 45px;
                height: 45px;
              }
            }

            .label {
              margin: 0 25px 10px 0;
              padding-bottom: 10px;
              font-family: "SF Pro Medium", sans-serif;
              font-size: 32px;
              line-height: 42px;
              border-bottom: 1px solid #474C5A;
            }

            .inner {
              padding-right: 25px;
              margin-bottom: 20px;
              /*max-height: calc(100vh - 560px);*/
              overflow-y: auto;

              .info {
                margin-bottom: 20px;
                color: #757A88;
              }

              .service_item {
                margin-bottom: 15px;
                display: flex;
                align-items: center;

                img {
                  margin-right: 10px;
                }
              }

              &::-webkit-scrollbar {
                width: 2px;
                background-color: #3a3aef;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #3f3fe8;
              }
            }

            .btn_send {
              margin-top: auto;
              margin-right: 25px;
              padding: 11px 12px 13px 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #757A88;
              border-radius: 16px;
              background-image: linear-gradient(#4ed13d, #4ed13d);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: 0% 100%;
              transition: background-size .5s, color .5s;
              cursor: pointer;

              &:hover {
                background-size: 100% 100%;
              }
            }
          }
        }

        &.active {
          transform: scale(1.05);
          margin: 0 30px 0 10px;

          .item {
            background: #474C5A;

            .label {
              border-color: #757A88;
            }
          }

          .icon {
            background: #343843 !important;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .slider_section {
      display: none;
      width: 100%;

      .list_elem {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        max-width: 325px;
        width: 100%;
        height: 100%;

        .content {
          margin: auto 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          .item {
            padding: 13px 0 13px 13px;
            display: flex;
            flex-direction: column;
            height: inherit;
            background: #343843;
            border-radius: 18px;

            .icon {
              margin-bottom: 15px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #474C5A;
              border-radius: 16px;

              img {
                width: 30px;
                height: 30px;
                border-radius: 12px;
              }
            }

            .label {
              margin: 0 15px 10px 0;
              padding-bottom: 10px;
              font-family: "SF Pro Medium", sans-serif;
              font-size: 24px;
              line-height: 29px;
              border-bottom: 1px solid #474C5A;
            }

            .inner {
              padding-right: 15px;
              margin-bottom: 15px;
              max-height: 200px;
              overflow-y: auto;

              .info {
                margin-bottom: 15px;
                color: #757A88;
              }

              .service_item {
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                }

                span {
                  font-size: 13px;
                  line-height: 16px;
                }

              }

              &::-webkit-scrollbar {
                width: 2px;
                background-color: #3a3aef;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #3f3fe8;
              }
            }

            .btn_send {
              margin-top: auto;
              margin-right: 15px;
              padding: 11px 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #757A88;
              border-radius: 16px;
              background-image: linear-gradient(#4ed13d, #4ed13d);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: 0 100%;
              transition: background-size .5s, color .5s;
              font-size: 13px;
              line-height: 16px;
              cursor: pointer;

              &:hover {
                background-size: 100% 100%;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .dots {
      margin: 20px auto 0 auto;
      width: fit-content;
      display: none;
      align-items: center;

      div {
        margin: 0 6px 6px 0;
        width: 10px;
        height: 10px;
        background: #343843;
        border-radius: 50%;
        transition: all .3s linear;

        &.active {
          background: #00A72F;;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .gradient_parent {
    /*overflow: hidden;*/
    z-index: -1;
  }
}

@media (max-width: 1570px) {
  .service {
    padding: 0 15px 0 15px;
  }
}

@media (max-width: 1200px) {
  .service {
    .container {
      .title {
        .h1 {
          margin-bottom: 22px;
          font-size: 54px;
          line-height: 74px;
        }
      }

      .list {
        li {
          margin-right: 10px;

          .content {
            .item {
              padding: 20px 0 20px 20px;
              min-height: initial;

              .label {
                font-size: 22px;
                line-height: 32px;
              }
            }
          }

          &.active {
            margin: 0 20px 0 10px;
            transform: scale(1.03);
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .service {
    .container {
      border-radius: 26px;

      .title.web, .list.web {
        display: none;
      }

      .slider_section, .title_mobile {
        display: block;
      }

      .slider_section {
        max-width: 730px;
        width: 100%;
        margin: 0 auto;
      }

      .dots {
        display: flex;
      }
    }

    .gradient_parent {
      .gradient {
        width: 1230px;
      }
    }
  }
}

@media (max-width: 790px) {
  .service {
    .container {
      .slider_section {
        max-width: 380px;
        width: 100%;
        margin: 0 auto;

        .list_elem {
          margin: 0 auto !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .service {
    .container {
      padding: 15px;

      .slider_section {
        max-width: 100%;

      }
    }

    .gradient_parent {
      top: 30%;
    }
  }
}

@media (max-width: 420px) {
  .service {
    .slick-slide {
      /* max-width: 335px;*/

      /* div:nth-child(1) {
         max-width: 325px;
       }*/
    }
  }
}
