.footer {
  position: relative;
  margin-top: auto;
  display: flex;
  width: 100%;
  color: white;
  z-index: 2;

  .col {
    padding: 50px 70px 50px 40px;
    display: flex;
    flex-direction: column;

    .h1 {
      font-size: 60px;
      line-height: 72px;
    }

    .text {
      margin-top: auto;
      opacity: 0.6;
    }

    &:nth-child(1) {
      max-width: 550px;
      width: 100%;
      background: #1D1E25;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    &:nth-child(2), &:nth-child(3) {
      padding: 50px 70px 50px 58px;
      max-width: 470px;
      width: 100%;

      .h1 {
        font-family: "SF Pro Medium", sans-serif;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
      }

      .list {
        margin-top: 62px;
        max-width: 169px;
        width: 100%;

        li {
          margin-bottom: 20px;
          color: #A2A1A4;
          cursor: pointer;
          transition: all .3s linear;

          &:hover {
            color: var(--green);
          }
        }
      }
    }

    &:nth-child(2) {
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: none;
    }

    &:nth-child(3) {
      max-width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

@media (max-width: 990px) {
  .footer {
    .col {
      padding: 30px 15px;

      .h1 {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 43px;
      }

      &:nth-child(2), &:nth-child(3) {
        padding: 30px 15px;

        .h1 {
          margin-bottom: 40px;
          font-size: 36px;
          line-height: 43px;
        }

        .list {
          margin-top: 0;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .start_project {

    .footer {
      flex-direction: column;

      .col {
        max-width: 100%;
      }
    }
  }
}