@keyframes show_drop-menu {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 550px;
  }
}

@keyframes hide_drop-menu {
  0% {
    opacity: 1;
    max-height: 550px;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
}

.header {
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  background: var(--black);
  z-index: 4;

  .container {
    padding-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .logo {
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .3s linear;

    &:hover {
      color: var(--green);
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    li {
      margin-right: 20px;
      cursor: pointer;
      transition: all .3s linear;
      user-select: none;

      a {
        text-decoration: none;
        transition: all .3s linear;
        color: white;
      }

      &:hover, a:hover {
        color: var(--green);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .send_request {
    padding: 11px 30px 13px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    cursor: pointer;
    background-image: linear-gradient(#4ed13d, #4ed13d);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size .5s, color .5s;
    user-select: none;

    &:hover {
      background-size: 100% 100%;
    }
  }

  .burger {
    display: none;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
  }

  .drop_menu {
    position: absolute;
    padding: 30px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw + 30px);
    top: 40px;
    left: -15px;
    background: #16151B;
    transition: all .3s linear;

    li {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
      transition: all .3s linear;
      cursor: pointer;

      &:hover {
        color: var(--green);
      }

      .feedback_form {
        margin-top: 10px;
        padding: 15px;
        width: 100%;
        max-width: 345px;
        background: #343843;
        border-radius: 24px;
        color: white;

        .discount_info {
          margin-bottom: 15px;
          max-width: 230px;
          width: 100%;
          font-family: "SF Pro Medium", sans-serif;
          font-size: 18px;
          line-height: 21px;
          text-transform: uppercase;
        }

        .input_form, .phone_mask {
          padding: 11px 15px;
          border-radius: 15px;
        }

        .month_end-discount {
          margin-bottom: 10px;
          font-size: 13px;
          opacity: 0.6;
        }

        .btn_send {
          margin-bottom: 15px;
          padding: 11px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 16px;
          background: #00A72F;
          font-size: 13px;
        }

        .disclosure_agreement {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    &.entering {
      animation: show_drop-menu .4s forwards;
    }

    &.entered {
      animation: show_drop-menu .4s forwards;
    }

    &.exiting {
      animation: hide_drop-menu .4s forwards;
    }
  }

  &.hide {
    z-index: 0;
  }
}

@media (max-width: 1560px) {
  .header {
    padding: 12px 15px;
  }
}

@media (max-width: 1050px) {
  .header {
    .logo {
      font-size: 24px;
      line-height: 29px;
    }

    .burger {
      display: flex;
    }

    .send_request, .navigation {
      display: none;
    }
  }
}